import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Layout from "./layout";

const Footer = ({ siteTitle }) => (
    <header
        style={{
            background: `snow`,
            marginBottom: `1.45rem`,
        }}
    >
        <div
            style={{
                margin: `auto auto`,
                maxWidth: 960,
                padding: `1.45rem 1.0875rem`,
                justifyContent: "center",
            }}
        >
            <h1 style={{ margin: 0 }}>
                <Link to="/" style={{color: `lightcoral`, marginRight: 20}}>About Me</Link>

                <Link to="/myWork/" style={{color: `lightcoral`, marginRight: 20}}>My Work</Link>

                <Link to="/myGarden/" style={{color: `lightcoral`, marginRight: 20}}>My Garden</Link>

                <Link to="/mySpotify/" style={{color: `lightcoral`, marginRight: 20}}>My Playlist</Link>

                <Link to="/myFitbit/" style={{color: `lightcoral`, marginRight: 20}}>My Fitbit</Link>


            </h1>
        </div>
    </header>
)

Footer.propTypes = {
    siteTitle: PropTypes.string,
}

Footer.defaultProps = {
    siteTitle: ``,
}


    export default Footer